@import '../../styles//customMediaQueries.css';

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  background-color: #000;

  @media (--viewportMedium) {
    position: relative;
    padding-top: 0;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.filterButton {
  width: max-content;
  margin-left: auto;
  margin-top: 10px;
  display: flex;
  gap: 8px;
  border: 0;

  @media (--viewportMedium) {
    display: none;
  }

  & svg {
    width: 18px;

    & path {
      stroke: #fff;
    }
  }
}

.layoutWrapperFilterColumn {
  display: none;

  & button {
    overflow: inherit;

    &:before {
      content: none;
    }

    &:hover {
      background-color: transparent !important;
      background: transparent !important;
    }

    &:focus {
      background-color: transparent;
      background: transparent !important;
      border: none !important;
    }
  }

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }

  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }

  /* @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  } */
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  &>div {
    max-width: 180px;
  }

  @media (--viewportMedium) {
    padding: 8px 24px 60px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 60px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 60px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 40px 24px 0 24px;

  @media (--viewportMedium) {
    /**
       * Calculate right padding to match Footer right border
       * and left padding together with border should be 60px.
       */
    padding: 24px 24px 0 24px;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
       * Calculate right padding to match Footer right border
       * and left padding together with border should be 60px.
       */

    padding: 49px calc((100vw - 1064px) / 2) 0 59px;
  }

  @media (min-width: 1440px) {
    padding: 49px calc((100vw - 1064px) / 2) 0 59px;
  }

  /* @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  } */
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
       * .container is using flexbox,
       * This specifies that searchResultContainer is taking 60% from the viewport width
       */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.searchPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & select {
    width: max-content;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 184.615% */
    letter-spacing: 0.3px;
    background: #000;
    padding: 0;
    border: none;
    box-shadow: none;

    &::placeholder {
      color: #9d9d9d;
    }

    &:focus {
      background: var(--Background);
    }
  }
}

.sortByContainer {
  position: relative;
  padding-left: 24px;
  width: max-content;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 12px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none"><path d="M11.4581 3V11.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/><path d="M14.4318 8.25L11.4581 11.25L8.48436 8.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/><path d="M4.02379 9V0.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/><path d="M1.05006 3.75L4.02378 0.75L6.9975 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
  }

  & select {
    &:hover {
      border: none !important;
    }
  }
}

.totalResults {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 125% */
  letter-spacing: -0.348px;
}

.filterModal {
  &>div {
    &>div {
      &>button {
        top: 60px !important;
      }
    }
  }

  & .filterColumnContent {
    padding-bottom: 50px;
    padding-right: 0;
    padding-top: 80px;
  }
}

.listingCardBorder {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 8px;
  border-radius: 5px;

  & .cardBody {
    margin-top: 10px;
    padding: 10px;
  }
}

.loadingCardImage {
  border-radius: 8px;
  height: 399px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: #ddd;
  position: relative;
  overflow: hidden;

  & .NoImage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 48px;
      height: 48px;
    }
  }

  &>div {
    background-color: #ddd;

    &:after {
      background: linear-gradient(90deg,
          rgba(237, 33, 36, 0.15) 15%,
          rgba(237, 33, 36, 0.17) 17%,
          rgba(237, 33, 36, 0.12) 12%);
    }
  }
}

.hits {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px;
  background-color: #000;
  margin-top: 23px;
  gap: 46px;

  @media (max-width: 1164px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  & .card {
    margin: 0 auto;
    max-width: 269px;
  }
}

.filterColumnContentLoading {
  padding-right: 30px;
}

.searchPageLoaderHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}