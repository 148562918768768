.paginationContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: #fff;
  background-color: #171819;
  padding: 19px 38px;
  margin-bottom: 69px;
  @media (max-width: 1024px) {
    padding: 19px 10px;
    margin-top: 40px;
  }
}
.paginationNumbers {
  display: flex;
  gap: 30px;
  @media (max-width: 1024px) {
    gap: 10px;
  }
  & button {
    border: none;
    box-shadow: none;
    color: #ddd;
    font-family: 'Sofia Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.348px;
    &.active {
        color: red;
      }
  }
}
.arrowBtn {
  border: none;
  cursor: pointer;
  &:disabled {
    color: gray;
    cursor: not-allowed;
  }
}


